.ProjectTypeTag {
  display: flex;
  align-items: center;
  justify-content: center;
  &.WHITE {
    
    &.AI {
      width: 60px;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: solid 1px #5e9eff;
      color: #5e9eff;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
    }
    &.CAL {
      width: 60px;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: solid 1px #c25eff;
      color: #c25eff;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
    }
    &.DESIGNER {
      width: 60px;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: solid 1px #ff8b1f;
      color: #ff8b1f;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
    }
    &.SITE_PLAN {
      width: 60px;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: solid 1px #95e4b3;
      color: #95e4b3;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
    }
    &.UNDEFINED {
      width: 60px;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: solid 1px #999999;
      color: #999999;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
    }

    &.NOCONFIG {
      width: 60px;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: solid 1px #999999;
      color: #999999;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
    }
  }
  &.DARK {
    border: 1px solid #ccc;
    color: #ccc;
    &.AI {
      width: 60px;
      box-sizing: border-box;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: 1px solid #01BAEF;
      color: #01BAEF !important;
      font-size: 10px;
      font-style: normal;
      font-weight: bold;
    }
    &.CAL {
      width: 60px;
      box-sizing: border-box;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: 1px solid #0075FF;
      color: #0075FF !important;
      font-size: 10px;
      font-style: normal;
      font-weight: bold;
    }
    &.DESIGNER {
      width: 60px;
      box-sizing: border-box;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: 1px solid #B71DFF;
      color: #B71DFF !important;
      font-size: 10px;
      font-style: normal;
      font-weight: bold;
    }
    &.SITE_PLAN {
      width: 60px;
      box-sizing: border-box;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: 1px solid #B71DFF;
      color: #B71DFF !important;
      font-weight: bold;
      font-style: normal;
      font-size: 10px;
    }
    &.UNDEFINED {
      width: 60px;
      box-sizing: border-box;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: solid 1px #999999;
      color: #999999;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
    }
    &.NOCONFIG {
      width: 60px;
      box-sizing: border-box;
      height: 20px;
      padding: 0px;
      border-radius: 20px;
      border: solid 1px #999999;
      color: #999999;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
    }
  }
}