.ProjectResult {
  background-color: #1a1e28;
  display: flex;
  flex-flow: column;
  min-height: calc(100vh - 280px);
  padding-bottom: 100px;

  .ProjectResultHeader {
    padding-bottom: 12px;
    background-color: #161a24;
    .wrapper {
      > .breadcrumb {
        text-align: right;
        font-size: 12px;
        color: #999;
      }
      padding-top: 25px;
      width: 1200px;
      margin: auto;
      .page-title {
        font-size: 30px;
        padding-top: 20px;
        display: flex;
        align-items: center;
        .project-name-no-edit {
          display: flex;
          align-items: center;
          width: 956px;
          > .project-name {
            max-width: 940px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .icon {
            margin-left: 10px;
            color: #999;
          }
          height: 50px;
          border: 1px solid #161a24;
          display: flex;
          align-items: center;
          &:hover {
            background-color: #273945;
            cursor: text;
          }
        }

        .project-name-cant-edit {
          display: flex;
          align-items: center;
          width: 956px;
          > .project-name {
            max-width: 940px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .icon {
            margin-left: 10px;
            color: #999;
          }
          height: 50px;
          border: 1px solid #161a24;
          display: flex;
          align-items: center;
        }

        > .project-name {
          width: 956px;
          .edit-btn {
            min-width: 0px;
            padding: 0px;
            width: 40px;
            height: 40px;
            .icon {
              color: #ccc;
            }
          }
          input {
            height: 50px;
            font-size: 30px;
            &::placeholder {
              font-size: 24px;
            }
          }
        }
        > .icon-group {
          margin-left: auto;
          display: flex;
          flex-flow: row;
          .favorite-btn {
            &:hover {
              background-color: inherit;
            }
            .icon {
              color: #ccc;
              &.active {
                color: #95e4b3;
              }
            }
          }
          .btn {
            margin-right: 5px;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
      .project-info {
        padding-top: 20px;
        padding-bottom: 12px;
        .id-type-status {
          display: flex;
          flex-flow: row;
          align-items: center;
          > .date {
            display: flex;
            flex-flow: row;
            align-items: center;
            > div {
              &last:last-child {
                margin-right: 0px;
              }
              margin-right: 20px;
              font-size: 12px;
              color: #999;
            }
          }
          > .user-project-id {
            color: #ccc;
            font-size: 14px;
            margin-right: 10px;
          }
          > .project-type {
            margin-right: 10px;
          }
          > .project-status {
            color: #ccc;
            font-size: 12px;
            margin-right: 10px;
          }
        }
        > .project-description {
          position: relative;
          display: flex;
          width: 956px;
          .project-description {
            color: #cccccc;
            width: 956px;
            margin-top: 10px;
            &.no-edit {
              background-color: #161a24;
              border: 1px solid #333;
              color: #999999;
              > .input-wrapper {
                border: 0px;
                height: 80px;
                &:hover {
                  background-color: #273945;
                }
              }
            }
            > .input-wrapper {
              textarea {
                height: 77px;
              }
            }
            &.cant-edit {
              height: 100px;
              background-color: #161a24;
              border: 1px solid #333;
              font-family: "Roboto", "Noto Sans KR";
              min-height: 39px;
              width: 100%;
              color: #ccc;
              padding: 10px;
              font-size: 14px;
              resize: vertical;
              line-height: 150%;
              overflow: scroll;
            }
          }
          .icon {
            position: absolute;
            bottom: 10px;
            right: 10px;
            &.edit {
              color: #999;
            }
            &.on-save {
              color: #ccc;
            }
            &.save {
              color: #ccc;
            }
          }
        }
      }
      .result-reports {
      }
    }
  }

  .ProjectResultBody {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    &.ALL {
      > .download-wrap {
        padding-top: 50px;
        display: flex;
        flex-flow: column;
        > .header {
          font-size: 18px;
          border-bottom: 1px solid #555;
          height: 50px;
          display: flex;
          align-items: ce;
          color: #48ddf2;
        }
        > .content {
          > .code {
            margin-top: 20px;
            color: #999;
            font-size: 14px;
            > .project-result-code-copy-btn {
              text-align: center;
              border-radius: 15px;
              background: inherit;
              border: 1px solid #999;
              color: #999;
              height: 20px;
              box-sizing: border-box;
              width: 80px;
              font-size: 12px;
              text-transform: none;
              margin-left: 10px;
              padding: 0px;
              outline: none;
              .tooltip {
                cursor: pointer;
              }
            }
          }
          > .download-btns {
            padding-top: 20px;
            display: flex;
            flex-flow: row;
            align-items: left;
            > .download-btn {
              font-size: 14px;
              width: 230px;
              margin-bottom: 10px;
              padding-left: 20px;
              padding-right: 20px;
              &.designer-download-btn {
                .icon {
                  color: #232732;
                }
              }
            }
          }
        }
      }
      > .project-status-info {
        padding-top: 50px;
        width: 100%;
        > .title {
          font-size: 18px;
          height: 40px;
          border-bottom: 1px solid #555;
        }
        > a {
          text-decoration: none;
          > .btn {
            width: 224px;
            margin-top: 20px;
          }
        }
      }
      .main-title {
        font-size: 24px;
        padding-top: 20px;
      }
      .result-chart-wrap {
        display: flex;
        flex-flow: row;
        padding-top: 50px;

        .result-chart {
          width: 956px;
          &.element {
            width: 224px;
            margin-left: auto;
            .header {
              border-bottom: 1px solid #555;
            }
          }
          .header {
            height: 50px;
            font-size: 18px;
            display: flex;
            flex-flow: row;
            align-items: center;
            border-bottom: 1px solid #555;
            .compare-tabs {
              width: 146px;
              .tooltip {
                flex: 1;
              }
            }
          }
          .content {
            padding-top: 20px;
            .description {
              height: 20px;
              color: #ccc;
              font-size: 12px;
              margin-bottom: 10px;
            }
            .chart {
            }
          }
        }
      }
      .result-list-wrap {
        padding-top: 30px;
        > .header {
          display: flex;
          flex-flow: row;
          align-items: center;
          height: 50px;
          border-bottom: 1px solid #555;
          > .title {
            font-size: 18px;
          }
          > .left-pannel {
            color: #cccccc;
            box-sizing: border-box;
            width: 125px;
            margin-left: 20px;
            height: 40px;
          }
          > .right-pannel {
            display: flex;
            flex-flow: row;
            margin-left: auto;
            .viewer-btn {
              width: 160px;
              font-size: 14px;
              margin-left: auto;
            }

            .compare-tabs {
              width: 146px;
              margin-left: 10px;
              .tooltip {
                flex: 1;
              }
            }
          }
        }
        > .content {
          display: flex;
          flex-flow: row wrap;
          > .project-card {
            margin-top: 30px;
            margin-right: 20px;
            > .content {
              > .compare-element {
                display: none;
              }
            }
          }

          &.COMPARE {
            display: inline-flex;
            flex-flow: column;
            > .content-wrap {
              display: flex;
              flex-flow: row;
              > .project-card {
                margin-top: 30px;
                margin-right: 20px;
                > .content {
                  > .compare-element {
                    display: flex;
                  }
                  > .key-value {
                    justify-content: center;
                    > .key {
                      display: none;
                    }
                  }
                }
              }

              > .compare-field {
                width: 224px;
                box-sizing: border-box;
                margin-top: 30px;
                padding: 20px;
                > .report {
                  color: #ccc;
                  font-size: 14px;
                }
                > .area-title {
                  padding-top: 152px;
                  font-size: 12px;
                  color: #999;
                }
                > .area {
                  font-size: 16px;
                  color: #ccc;
                  margin-bottom: 15px;
                }
                > .field {
                  margin-top: 0px;
                  box-sizing: border-box;
                  color: #999;
                  height: 40px;
                  display: flex;
                  align-items: center;
                }
              }
              > .NONE-SELECT {
                width: 976px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ccc;
                font-size: 14px;
                padding: 0px;
              }
            }
          }
        }
      }
      .project-summary {
        padding-top: 50px;
      }
    }

    &.DETAIL {
      .main-title {
        font-size: 24px;
        padding-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        > .title {
          width: auto;
          margin-right: 10px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 26px;
          line-height: 128.7%;
          /* or 33px */

          display: flex;
          align-items: center;
          letter-spacing: -0.05em;

          color: #eeeeee;
        }
        > .solver-type {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 7px 10px;
          height: 30px;
          min-width: 50px;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.05em;

          background: #1a1e28;
          border: 1px solid #cccccc;
          box-sizing: border-box;
        }
      }
      > .content {
        display: flex;
        flex-flow: row;
        padding-top: 50px;
        .result-img-files {
          width: 468px;
          position: relative;
          .webgl-btn {
            margin-top: 20px;
            width: 100%;
            font-size: 18px;
            font-weight: bold;
            color: #232732;
          }
          .cad-img {
            width: 466px;
            height: 466px;
            > img {
              width: 466px;
              height: 466px;
              border: 1px solid #232732;
              background-color: #161a24;
            }
          }
          .floor-btn-wrap {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            .tabs {
              height: 30px;
              .tab {
                height: 26px;
                font-size: 12px;
              }
            }
            .btn {
              min-width: 70px !important;
              min-height: 36px !important;
            }
          }
          .files {
            padding-top: 30px;
            .header {
              font-size: 18px;
              height: 50px;
              width: 100%;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #555;
              .row {
                display: flex;
                flex-direction: row;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                > .title {
                  width: 50%;
                  display: flex;
                  height: 40px;
                  margin-bottom: 10px;
                  align-items: center;
                }
                > .download-btn {
                  font-size: 14px;
                  width: 230px;
                  margin-bottom: 10px;

                  padding-left: 20px;
                  padding-right: 20px;
                  &.designer-download-btn {
                    .icon {
                      color: #232732;
                    }
                  }
                }
              }
            }
            .content {
              padding-top: 20px;
              .designer {
                display: flex;
                flex-flow: row;
                align-items: center;
                color: #999;
                padding-bottom: 10px;
                > .key {
                  color: #ccc;
                  margin-left: 5px;
                }
                > .project-result-code-copy-btn {
                  text-align: center;
                  border-radius: 15px;
                  background: inherit;
                  border: 1px solid #999;
                  color: #999;
                  height: 20px;
                  box-sizing: border-box;
                  width: 80px;
                  font-size: 12px;
                  text-transform: none;
                  margin-left: 10px;
                  padding: 0px;
                  outline: none;
                  .tooltip {
                    cursor: pointer;
                  }
                }
              }
              .report-files {
                padding-top: 10px;
                display: flex;
                flex-flow: row wrap;
                > .download-btn {
                  font-size: 14px;
                  width: 230px;
                  margin-bottom: 10px;
                  padding-left: 20px;
                  padding-right: 20px;
                  &.designer-download-btn {
                    .icon {
                      color: #232732;
                    }
                  }
                }
              }
            }
          }
        }
        .result-summary {
          width: 712px;
          margin-left: 60px;
          display: flex;
          flex-direction: column;
          > .title {
            font-size: 18px;
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #555;
          }
          .content {
            width: 100%;
            display: flex;
            flex-flow: row;
            .values-left {
              .value-lg {
                width: 185px;
                padding-top: 20px;
                padding-right: 20px;
              }
            }
            .values-right {
              display: flex;
              flex-flow: row wrap;
              margin-left: auto;

              .value-md {
                &.value-small {
                  font-size: 13px;
                }
                padding-top: 20px;
                padding-right: 20px;
                width: 102px;

                &.last {
                  padding-right: 0px;
                }

                &.lt-small {
                  letter-spacing: -0.6px;
                }
              }
              .value-lg {
                width: 185px;
                padding-top: 20px;
                padding-right: 20px;
              }

              .housing-type-chart {
                width: 100%;
                margin-top: 20px;
                padding: 20px 0px 20px 20px;
                width: 468px;
                border: 1px solid #333;
                box-sizing: border-box;
                > .title {
                  color: #999;
                  font-size: 12px;
                  margin-bottom: 20px;
                }
                > .HousingChart {
                  .C3Chart-legend {
                    margin-top: auto;
                    max-height: 175px;
                    margin-bottom: auto;
                    box-sizing: border-box;
                    overflow-y: auto;
                    justify-content: unset;
                  }
                  .legend-item {
                    > .legend-id {
                      display: block;
                      width: 130px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                    > .proportion {
                      margin-left: 10px;
                      width: 30px;
                    }
                    width: 220px;
                    margin-left: 10px;
                  }
                }
              }
            }
          }

          .floor_content {
            width: 100%;
            .moreview {
              width: 672px;
              height: 20px;
              margin-top: 16px;
              font-family: Inter;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 18px;
              text-align: right;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              letter-spacing: -0.05em;
              cursor: pointer;
              color: #cccccc;
              &:hover {
                background-color: #273945;
              }
            }
            .floortitle {
              margin-top: 34px;
              margin-bottom: 15px;
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 128.7%;
              /* identical to box height, or 21px */

              display: flex;
              align-items: center;
              letter-spacing: -0.05em;

              color: #cccccc;
            }
            .table-row {
              height: 30px;
              width: 672px;

              .cellhead {
                width: 84px;
                height: 30px;
                padding: 0px;
                text-align: center;
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                letter-spacing: -0.05em;
                color: #cccccc;

                background: #232732;
                border: 1px solid #333333;
                border-radius: 0px;
              }
              .cellfirst {
                width: 84px;
                height: 30px;
                padding: 0px;
                background: #1a1e28;
                border: 1px solid #333333;
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                letter-spacing: -0.05em;
                color: #cccccc;
              }
              .celldata {
                width: 84px;
                height: 30px;
                padding: 0px;
                background: #161a24;
                border: 1px solid #333333;
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                letter-spacing: -0.05em;
                color: #cccccc;
              }
              .totalfirst {
                width: 84px;
                height: 30px;
                padding: 0px;
                background: #1a1e28;
                border: 1px solid #333333;
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height, or 150% */

                text-align: center;
                letter-spacing: -0.05em;
                color: #48ddf2;
              }
              .totaldata {
                width: 84px;
                height: 30px;
                padding: 0px;
                background: #161a24;
                border: 1px solid #333333;
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height, or 150% */

                text-align: center;
                letter-spacing: -0.05em;

                color: #48ddf2;
              }
            }
          }
        }
      }
    }
  }
  .ProjectResultFooter {
    > .wrapper {
      display: flex;
      margin: auto;
      padding-top: 45px;
      width: 1200px;
      > .buttons {
        display: flex;
        flex-flow: row;
        margin-left: auto;
        > .btn {
          margin-right: 5px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
  > .scroll-top-btn {
    width: 45px;
    height: 45px;
    position: fixed;
    right: 30px;
    bottom: 50px;
  }
}

.ProjectResultList {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  &.NORMAL {
  }
  &.COMPARE {
    > .project-card-header {
      margin-right: 20px;
      width: 224px;
      color: #999;
      margin-top: 30px;
      font-size: 12px;
      > .title {
        padding: 0px 10px;
        margin-top: 20px;
        height: 20px;
        color: #eee;
      }
      > .project-site {
        padding: 0px 10px;
        margin-top: 10px;
        height: 224px;
        display: flex;
        flex-flow: column;
        > .info {
          margin-top: auto;
          display: flex;
          flex-flow: column;
          > .area {
            color: #ccc;
            font-size: 16px;
          }
        }
      }
      > .content {
        margin: 10px 0px 20px 0px;
        > .key {
          padding: 0px 10px;
          height: 40px;
          display: flex;
          align-items: center;
          &.hover {
            background-color: #273945;
          }
        }
      }
    }

    .project-card {
      .content {
        .key-value {
          cursor: text;
          &.hover {
            background-color: #273945;
          }
        }
      }
    }
  }
  .project-card {
    margin-right: 20px;
    margin-top: 30px;
    &:nth-child(5) {
      margin-right: 0px;
    }
    &:nth-child(10) {
      margin-right: 0px;
    }
  }
}
