.ProjectValue {
  >.wrapper {
    height: 58px;
    box-sizing: border-box;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    >.project-value-title {
      color: #999;
      font-size: 12px;      
      display: flex;
      flex-direction: row;
      align-items: center;  
      justify-content: flex-start;
    }
    >.question {
      width: 14px;
      height: 14px;  
      display: flex;
      flex-direction: row;
      align-items:flex-end;  
      justify-content: flex-start;
    }
    >.project-value-value {
      padding-top: 5px;
      color: #ccc;
      font-size: 14px;
    }
  }
}