.ProjectFormMap {
  height: calc(100vh - 60px);
  width: 100%;
  background-color: #232732;
  position: relative;
  .snack-bar {
    position: absolute;
    top: 20px;
    z-index: 0;
    padding: 0px;
    // transform: translate(0px, 130px);
    >div {
      background-color: #0c111b;
      color: #ccc;
      
      display: flex;
      justify-content: center;
      max-width: 2200px;
      padding: 0px 20px;
      
      min-height: 40px;
      height: 40px;
      box-sizing: border-box;
      align-items: center;
      .icon {
        color: #48ddf2;
      }
    }
  }
  .absolute {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-flow: row;
    height: calc(100vh - 60px);
    box-sizing: border-box;
  }
  .site-config-wrap {
    width: 420px;
    height: calc(100vh - 60px);
    background-color: #161A24;
    box-sizing: border-box;
    overflow-y: auto;
    >.search-address {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom:1px solid #333333;
      width: 360px;
      margin: auto;
      .icon-btn {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.search-btn {
          min-width: 40px;
          min-height: 40px;
          width: 40px;
          height: 40px;
        }
        &.close-btn {
          min-width: 20px;
          min-height: 40px;
          width: 20px;
          height: 40px;
          .icon {
            width: 14px !important;
            height: 14px !important;
          }
        }
        svg {
          color: #ccc;
        }
      }
    }
    >.header {
      padding: 20px 30px;
      border-bottom: 1px solid #333333;
      .content {
        display: flex;
        flex-flow: column;
        .project-type-title {
          display: block;
          width: 100%;
          margin-bottom: 5px;
          height: 20px;
          font-size: 14px;
          // &.AI {
          //   color:#01BAEF;
          // }
          // &.DESIGNER {
          //   color: #905CFF;
          // }
        }
        .title-header {
            display: flex;
            align-items: center;
        }
        position: relative;
        margin-bottom: 15px;
        >.title {

        }
        >.site-tooltip {
          margin-left: auto;
        }
      }
    }
    .content {

      .site-config {
        padding-top: 10px;
        align-items: center;
        display: flex;
        padding-top: 10px;
        padding-left: 30px;
        padding-right: 30px;
        .config-set {
          display: flex;
          flex-flow: column;
          width: 100%;
          .config, .header {
            display:flex;
            flex-flow: row;
            align-items: center;
            margin-bottom: 5px;
            margin-top: 5px;
            .required {
              display: flex;
              margin-bottom: auto;
              margin-top: 4px;
              margin-left: 3px;
            }
            .action {
              display: flex;
              flex-flow: row;
              margin-left: 10px;
              width: 100%;
              .action-btn {
                font-size: 12px;
                width: 100px;
                &.half {
                  min-width: 47.5px;
                  width: 47.5px;
                  box-sizing: border-box;
                }
                &.m-l {
                  margin-left: 5px;
                }
              }
            }

            .site-btn {
              width: 238.66px;
              display: flex;
              flex-flow: row;
              align-items: center;
              justify-content: left;
              padding-left: 10px;
              padding-right: 10px;

              .num {
                width: 20px;
                height: 20px;
                &.AI {
                background-color:  #01BAEF; 
                }
                &.DESIGNER {
                  background-color:  #B71DFF; 
                }
                color: #232732;
                font-size: 14px;
                margin-right: 10px;
                &.disabled {
                  background-color: #333;
                  color: #555;
                }
              }
              .name {
                color: inherit;
              }
              .complete-icon {
                display: flex;
                margin-left: auto;
                width: 20px;
                height: 20px;
                color: #555555;
                &.completed {
                  color: #95e4b3;
                }
                &.err {
                  color: #e81c1c;
                }
              }
            }
          }
          .require-icon {
            width: 3px;
            height: 3px;
            margin-right: 12px;
          }
          
        }
      }
      .run {        
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 30px;
        .run-AI {
          &:hover {
            background-position: right center; 
          }
          width: 360px;
          background-image: linear-gradient(to right, #48ddf2 0%, #95e4b3 51%, #48ddf2 100%);
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: bold;
          color: #232732;
          transition: 0.5s;
          background-size: 200% auto;
        }
        .run-DESIGNER {
          &:hover {
            background-position: right center; 
          }
          width: 360px;
          background-image: linear-gradient(to right, #48ddf2 0%, #95e4b3 51%, #48ddf2 100%);
          font-size: 18px;
          font-weight: bold;
          color: #232732;
          transition: 0.5s;
          background-size: 200% auto;
        }
      }
      .site-area {
        width: 360px;
        padding: 26px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        background-color: rgba(238, 238, 238, 0.05);
        >div {
          display: flex;
          flex-flow: row;
          margin: 2.5px;
          align-items: center;
          .key {
            color: #999999;
            font-size: 12px;
          }
          .value {
            color: #cccccc;
            margin-left: auto;
            font-size: 12px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }

    .btn-group {
      box-sizing: border-box;
      width: 360px;
      height: 44px;
      margin: auto;
      padding: 1px;
      background-color: #383b45;
      display: flex;
      flex-flow: row;
      border-radius: 6px;
      >.btn {
        background-color: #383b45;
        margin: 1px;
        flex: 1;
        font-size: 14px;
        color: #eeeeee;

        &.active, &:hover {
          background-color: #eeeeee;
          color: #232732;
          font-weight: bold;
        }
      }
    }
  }
  .road-config-wrap {
    width: 420px;
    height: calc(100vh - 60px);
    position: absolute;
    z-index: 1000;
    background-color: #1a1e28;
    box-sizing: border-box;
    // >.header {
    //   display: flex;
    //   flex-flow: column;
    //   font-size: 12px;
    //   color: #999999;
    //   padding: 20px 30px 0px 30px;
    //   .title {
    //     display: flex;
    //     >.icon {
    //       width: 20px;
    //       height: 20px;
    //       margin-right: 5px;
    //       cursor: pointer;
    //     }
    //   }
    //   >.content {
    //     display: flex;
    //     flex-flow: row;
    //     >.info {
    //       margin-top: 20px;
    //       width: 224px
    //     }
    //     >.complete-btn {
    //       width: 112px;
    //       margin-left: auto;
    //       margin-top: 20px;
    //     }
    //   }
      
    // }
    // >.content-wrap {
    //   display: flex;
    //   flex-flow: column;
    //   color: #ccc;
    //   overflow-y: auto;
    //   height: calc(100% - 96px - 140px);
    //   padding: 20px 30px;
    //   box-sizing: border-box;
    //   >.road-wrap {
    //     border-bottom: 1px solid #333;
    //     >.road-div {
    //       display: flex;
    //       flex-flow: row;
    //       align-items: center;
    //       margin-bottom: 20px;
    //       margin-top: 20px;
    //       >.checkbox {
    //         input {
    //           margin-top: 5px;
    //         }
    //         padding: 5px 5px 5px 0px;
    //         svg {
    //           width: 20px;
    //           height: 20px;
    //         }
    //       }
    //     }
    //     >.content {
    //       display: flex;
    //       align-items: center;
    //       margin-bottom: 10px;
    //       padding-left: 20px;
    //       &:last-child {
    //         margin-bottom: 20px;
    //       }
    //       .road-project-input {
    //         margin-left: auto;
    //       }
    //       >.checkbox {
    //         input {
    //           margin-top: 5px;
    //         }
    //         padding: 5px 5px 5px 0px;
    //         svg {
    //           width: 20px;
    //           height: 20px;
    //         }
    //       }
    //       .input {
    //       }
    //     }
    //   }
    // }
    // >.set-selected-road {
    //   height: 140px;
    //   display: flex;
    //   flex-flow: column;
    //   border-top: 1px solid #333;
    //   padding: 30px;
    //   box-sizing: border-box;
    //   .selected-road-btn {
    //     width: 175px;
    //     margin-top: 10px;
    //   }
    //   >.top {
    //     display: flex;
    //     flex-flow: row;
    //     align-content: center;
    //     >.title {
    //       display: flex;
    //       align-items: center;
    //       color: #ccc;
    //       font-size: 14px;
    //     }
    //     .input {
    //       margin-left: auto;
    //     }
    //     .selected-road-input {
    //     }
    //   }
    // }
  }
  .site-config-btn {
    position: absolute;
    right: -120px;
    background-color: #161A24;
    border-radius: 0px 6px 6px 0px;
    height: 40px;
    width: 120px;
    color: #ccc;
    font-size: 12px;
    border-left: 1px solid #333;
    &:hover {
      background-color: #161A24;
      color: #eee;
    }
  }
  .map-converter {
    position: absolute;
    right: -50px;
    top: 50px;
    width: 126px;
    height: 82px;
    &.tabs {
      .tab {
        height: 36px !important;
        font-size: 10px !important;
      }
    }
  }
  .map-options {
    position: absolute;
    display: flex;
    flex-flow: column;
    top: 137px;
    right: -50px;
    .area {
      margin-top: 5px;
    }
    .distance {
      margin-top: 5px;
    }
  }
  .map {
    width: 100%;
    height: calc(100vh - 60px);
    outline: none;
  }

  .construction-line-info {
    position: absolute;
    right: -160px;
    bottom: 30px;
    background-color: white;
    padding: 20px;
    width: 150px;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }

  .img-btn {
    img {
      margin-right: 10px;
    }
    .img {
      display: block;
    }
    .img-active {
      display: none;
    }
    &.active, &:hover {
      .img {
        display: none;
      }
      .img-active {
        display: block;
      }
    }
  }

  .bottom-error{
    margin: 15px 30px 0 30px;
    box-sizing: border-box;
    background-color: rgba(255, 176, 104, 0.2) ; // #FFB068;
    height: 48px;
    width: 360px;
    color: #cccccc;
    display: flex;
    justify-content: space-between;
    .vector{
      width: 38px;
      display: flex;
      // .area-warning-icon{
      //   width: 16px;
      // }
      .area-warning-icon {
        width: 16px !important;
        height: 16px !important;
        margin: 8.33px auto;
        justify-content: center;
          
        color: #FFB068;
      }
  
    }
    span{
      width: 322px;
      font-size: 12px;
      font-weight: 400;  
      margin: 5px auto 7px 0;
    
    }
  }  

}


