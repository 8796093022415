.Modal {  
  z-index: 9999999;
  >div {
    >div { // MODAL ROOT
      background-color: #1A1E28;
      overflow-y: hidden; 
      overflow-x: hidden;
      .modal-content {
        overflow-y: scroll;
        color: #ccc;
        font-size: 14px;
        box-sizing: border-box;
      }
      .modal-title {
        color: #eee;
        font-size: 14px;
        >.close-btn {
          padding: 0px;
          min-width: 20px;
          width: 20px;
          height: 20px;
          >.icon {
            width: 20px;
            height: 20px;
            color: #555555;
          }
        }
      }
      .modal-action {
        >.btn {
          &.positive-btn {
            color: #ccc;
            background-color: #383b45;
            &:hover {
              background-color: #60626a;
              color: #eee;
            }
          }
          &.negative-btn {
            color: #999;
            background-color: #232732;
            &:hover {
              background-color: #60626A;
            }
          }
        }
      }      
    }
  }
  &.simple {
    >div {
      >div { // MODAL ROOT
        width: 380px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        .modal-title {
          height: 20px;
          background-color: inherit;
          padding: 0px;
          width: 340px;
          font-size: 14px;
          display: flex;
          align-items: center;
          >.close-btn {
            margin-left: auto;
            padding: 0px;
            min-width: 20px;
            .icon {
              width: 20px;
              height: 20px;
              color: #555555;
            }
          }
        }
        .modal-content {
          max-height: 400px;
          background-color: inherit;
          margin: 20px 0px;
          width: 340px;
          > div {
            padding: 0px;
          }
        }
        .modal-action {
          background-color: inherit;
          padding: 0px;
          width: 340px;
          flex-direction: row-reverse;
          display: flex;
          >.btn {
            height: 30px;
            font-size: 12px;
            box-shadow: none;
            box-sizing: border-box;
            font-family: "Roboto", "Noto Snas KR";
            
            &.positive-btn {
            }
            &.negative-btn {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  &.normal {
    >div {
      >div { // MODAL ROOT
        width: auto;
        max-width: 720px;
        padding: 30px;
        display: flex;
        box-sizing: border-box;
        overflow-y: hidden;
        .modal-title {
          background-color: inherit;
          padding: 0px;
          width: 100%;
          font-size: 14px;
          text-align: left;
          display: flex;
          align-items: center;
          height: 20px;
          .close-btn {
            margin-left: auto;
          }
          .icon {
            width: 20px;
            height: 20px;
            color: #555555;
          }
        }
        .modal-content {
          max-height: 400px;
          background-color: inherit;
          margin: 20px 0px;
          padding-right: 10px;
          width: fit-content;
          > div {
            color: inherit;
            padding: 0px;
          }
        }
        .modal-action {
          background-color: inherit;
          padding: 0px;
          flex-direction: row-reverse;
          display: flex;
          >.btn {
            height: 30px;
            font-size: 12px;
            box-shadow: none;
            box-sizing: border-box;
            font-family: "Roboto", "Noto Snas KR";
            &.positive-btn {
              &:hover {
              }
            }
            &.negative-btn {
              margin-right: 5px;
              &:hover {
              }
            }
          }
        }
      }
    }
  }
  &.big {
    >div {
      >div { // MODAL ROOT
        width: auto;
        max-width: 1200px;
        padding: 30px;
        display: flex;
        box-sizing: border-box;
        overflow-y: hidden;
        .modal-title {
          background-color: inherit;
          padding: 0px;
          width: 100%;
          font-size: 14px;
          text-align: left;
          display: flex;
          align-items: center;
          height: 20px;
          .close-btn {
            margin-left: auto;
          }
          .icon {
            width: 20px;
            height: 20px;
            color: #555555;
          }
        }
        .modal-content {
          max-height: 400px;
          background-color: inherit;
          margin: 20px 0px;
          padding-right: 10px;
          width: fit-content;
          > div {
            color: inherit;
            padding: 0px;
          }
        }
        .modal-action {
          background-color: inherit;
          padding: 0px;
          flex-direction: row-reverse;
          display: flex;
          >.btn {
            height: 30px;
            font-size: 12px;
            box-shadow: none;
            box-sizing: border-box;
            font-family: "Roboto", "Noto Snas KR";
            &.positive-btn {
              &:hover {
              }
            }
            &.negative-btn {
              margin-right: 5px;
              &:hover {
              }
            }
          }
        }
      }
    }
  }
  &.mobile {
    >div {
      >div { // MODAL ROOT
        width: auto;
        max-width: 580px;
        max-height: 520px;
        padding: 30px;
        display: flex;
        box-sizing: border-box;
        overflow-y: hidden;
        .modal-title {
          background-color: inherit;
          padding: 0px;
          width: 100%;
          font-size: 14px;
          text-align: left;
          display: flex;
          align-items: center;
          height: 20px;
          .close-btn {
            margin-left: auto;
          }
          .icon {
            width: 20px;
            height: 20px;
            color: #555555;
          }
        }
        .modal-content {
          max-height: 400px;
          background-color: inherit;
          margin: 20px 0px;
          padding-right: 10px;
          width: fit-content;
          > div {
            color: inherit;
            padding: 0px;
          }
        }
        .modal-action {
          background-color: inherit;
          padding: 0px;
          flex-direction: row-reverse;
          display: flex;
          >.btn {
            height: 30px;
            font-size: 12px;
            box-shadow: none;
            box-sizing: border-box;
            font-family: "Roboto", "Noto Snas KR";
            &.positive-btn {
              &:hover {
              }
            }
            &.negative-btn {
              margin-right: 5px;
              &:hover {
              }
            }
          }
        }
      }
    }
  }
}